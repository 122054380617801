@import url("https://use.typekit.net/qif4fcq.css");
@import "~antd/dist/antd.less";
@tailwind base;
@tailwind components;
@tailwind utilities;

.ql-editor {
  min-height: 250px;
  background-color: white;
}
.anticon svg {
  display: block;
}
.ant-card-type-inner .ant-card-head-title {
  font-size: 15px;
  font-weight: 600;
  color: #9d7c38;
}
.login-page {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url("./assets/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.signatureCanvas {
  border: 1px solid #ddd;
  width: 100%;
  height: 300px;
}

.bg404 {
  background-image: url("./assets/images/404.jpg");
}
.container {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  background-color: white !important;
}

@primary-color: #9d7c38;